<template>
  <div class="SignUp1">
    <h1 class="title">{{ $t("Register.885@sign_up") }}</h1>
    <div class="mt-4 contentBox">
      <!-- First Name  ==  Last Name -->
      <div class="messageError" v-if="registered" v-html="alreadyEmail"></div>
      <div class="d-flex justify-content-between">
        <div class="form-group nameInput">
          <label class="appendAsterisk">{{
            $t("Register.96b@first_name")
          }}</label>
          <input
            type="text"
            class="form-control mb-0"
            placeholder="Lin"
            v-model="form.first_name"
          />
        </div>
        <div class="form-group nameInput">
          <label class="appendAsterisk">{{
            $t("Register.bba@last_name")
          }}</label>
          <input
            type="text"
            class="form-control mb-0"
            placeholder="Mina"
            v-model="form.last_name"
          />
        </div>
      </div>
      <!-- Email  -->
      <div class="form-group">
        <label class="appendAsterisk">{{
          $t("Register.e1d@email_address")
        }}</label>
        <input
          type="email"
          class="form-control mb-0"
          placeholder="Email Address"
          v-model="form.email"
        />
      </div>
      <!-- Password -->
      <div class="form-group">
        <label class="appendAsterisk">{{ $t("Register.609@password") }}</label>
        <input
          type="password"
          class="form-control mb-0"
          :placeholder="$t('Register.609@password')"
          v-model="form.password"
          @focus="tips"
          @blur="tipdown"
        />
        <div v-if="showTips" class="tip">
          <div class="tip-line"></div>
          <div class="tip-main">
            <!-- <span style="color: #ffba68"
              ><i class="ri-file-warning-line"></i
            ></span> -->
            <!-- <span>Password length must be at least 8 characters and must include at least 1 of the following: number,
              upper case letter & lower case letter.</span> -->
            <span>{{ $t("Register.831@password_length_of_8") }}</span>
          </div>
        </div>
      </div>
      <!-- Confirm Password -->
      <div class="form-group">
        <label class="appendAsterisk">{{
          $t("Register.585@confirm_password")
        }}</label>
        <input
          type="password"
          class="form-control mb-0"
          :placeholder="$t('Register.42d@enter_the_password_a')"
          v-model="confirmPassword"
          @blur="passwordValidation"
        />
        <div v-if="isVerified" class="tip">
          <div class="tip-line"></div>
          <div class="tip-main">
            <span style="color: #ffba68"
              ><i class="ri-file-warning-line"></i
            ></span>
            <span>{{ $t("Register.fcf@two_password_entries") }}</span>
          </div>
        </div>
      </div>
      <!-- Country 国家-->
      <div class="form-group">
        <label class="appendAsterisk">{{
          $t("Register.0b9@country_region")
        }}</label>
        <select
          class="form-control"
          v-model="form.nation_id"
          @change="handleChangeCountry(form.nation_id)"
        >
          <option value="">{{ $t("Register.a73@please_select") }}...</option>
          <option v-for="v in countryList" :key="v.id" :value="v.id">
            {{ v.name }}
          </option>
        </select>
      </div>
      <!-- State/Provience 州/省-->
      <div class="form-group" v-if="regionList.length > 0">
        <label>{{ $t("Register.d58@state_provience") }}</label>
        <select class="form-control" v-model="form.province_id">
          <option value="">{{ $t("Register.a73@please_select") }}...</option>
          <option v-for="v in regionList" :key="v.id" :value="v.id">
            {{ v.name }}
          </option>
        </select>
      </div>
      <!-- Birthday -->
      <div class="form-group">
        <label class="appendAsterisk">{{ $t("Register.cef@birthday") }}</label>
        <div>
          <el-date-picker
            style="width: 100%"
            v-model="form.birthday"
            type="date"
            :placeholder="$t('Register.b16@select_date')"
            :default-value="DefaultDate"
            value-format="yyyy-MM-dd"
            :editable="false"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="form-group">
        <label class="appendAsterisk"> {{ $t("Register.fb3@Gender") }} </label>
        <select class="form-control" v-model="sex">
          <option v-for="(v, index) in sexList" :key="index" :value="index">
            {{ v }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label>{{ $t("Register.82d@national_organizatio") }}</label>
        <select
          class="form-control"
          v-model="organ"
          value-key="id"
          @change="getSonOrgan(3, organ);getSonOrgan(1, organ)"
        >
          <option value="">{{ $t("Register.a73@please_select") }}...</option>
          <option v-for="v in organList" :key="v.id" :value="v">
            {{ v.name }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label>{{ $t("Register.6fc@regional_organizatio") }}</label>
        <select
          class="form-control"
          v-if="regionalList.length > 0"
          v-model="regional"
          @change="getSonOrgan(1, regional)"
        >
          <option value="">{{ $t("Register.a73@please_select") }}...</option>
          <option v-for="v in regionalList" :key="v.id" :value="v">
            {{ v.name }}
          </option>
        </select>
        <select v-else class="form-control" v-model="regional" disabled>
          <option :value="{}">
            {{
              $t("Register.5bf@There_is_no_region_organization_in_this_area")
            }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label>{{ $t("Register.747@local_organization") }}</label>
        <select
          class="form-control"
          v-if="localList.length > 0"
          v-model="local"
        >
          <option value="">{{ $t("Register.a73@please_select") }}...</option>
          <option v-for="v in localList" :key="v.id" :value="v.id">
            {{ v.name }}
          </option>
        </select>
        <select v-else class="form-control" v-model="local" disabled>
          <option value="">
            {{ $t("Register.d46@There_is_no_local_organization_in_this_area") }}
          </option>
        </select>
      </div>
      <!-- 用户协议 -->
      <div class="d-inline-block w-100 d-flex justify-content-between sign_up">
        <div>
          <div class="custom-control custom-checkbox mt-2 pt-1">
            <input
              type="checkbox"
              class="custom-control-input"
              id="termsAndConditions"
              v-model="termsAndConditions"
            />
            <label
              class="custom-control-label"
              for="termsAndConditions"
            ></label>
            {{ $t("Register.f93@i__accept") }}
            <a v-b-modal.noticeToUsers href="#">
              {{ $t("Register.771@terms_and_conditions") }}</a
            >
          </div>
          <div class="custom-control custom-checkbox mt-2 pt-1">
            <input
              type="checkbox"
              class="custom-control-input"
              id="privacyPolicy"
              v-model="privacyPolicy"
            />
            <label class="custom-control-label" for="privacyPolicy"></label>I
            {{ $t("Register.33a@accept") }}
            <a v-b-modal.privacy href="#">
              {{ $t("Register.000@privacy_policy") }}</a
            >
          </div>
        </div>
        <button
          :disabled="!(termsAndConditions && privacyPolicy)"
          type="submit"
          @click="signUp"
          class="btn btn-primary float-right"
          style="width: 80px; height: 35px"
        >
          {{ $t("Register.885@sign_up") }}
        </button>
      </div>
      <div class="sign-info">
        <span class="dark-color d-inline-block line-height-2">
          {{ $t("Register.aaf@already_have_account") }}?

          <router-link class="textColor" :to="{ name: 'auth1.sign-in1' }">
            {{ $t("Register.f8f@log_in") }}</router-link
          >
        </span>
      </div>
    </div>

    <b-modal id="noticeToUsers" hide-footer title="Terms of User">
      <div>
        <h5>TERMS OF USE</h5>
        <h6>Last updated March 24, 2021</h6>
        <h5>AGREEMENT TO TERMS</h5>
        <p>
          These Terms of Use constitute a legally binding agreement made between
          you, whether personally or on behalf of an entity (“you”) and Junior
          Chamber International, Inc., doing business as JCI ("JCI", “we”, “us”,
          or “our”), concerning your access to and use of the
          <a href="https://jci.cc">https://jci.cc</a> website as well as any
          other media form, media channel, mobile website or mobile application
          related, linked, or otherwise connected thereto (collectively, the
          “Site”). You agree that by accessing the Site, you have read,
          understood, and agreed to be bound by all of these Terms of Use. IF
          YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE
          EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE
          IMMEDIATELY.
        </p>
        <p>
          Supplemental terms and conditions or documents that may be posted on
          the Site from time to time are hereby expressly incorporated herein by
          reference. We reserve the right, in our sole discretion, to make
          changes or modifications to these Terms of Use at any time and for any
          reason. We will alert you about any changes by updating the “Last
          updated” date of these Terms of Use, and you waive any right to
          receive specific notice of each such change. It is your responsibility
          to periodically review these Terms of Use to stay informed of updates.
          You will be subject to, and will be deemed to have been made aware of
          and to have accepted, the changes in any revised Terms of Use by your
          continued use of the Site after the date such revised Terms of Use are
          posted.
        </p>
        <p>
          The information provided on the Site is not intended for distribution
          to or use by any person or entity in any jurisdiction or country where
          such distribution or use would be contrary to law or regulation or
          which would subject us to any registration requirement within such
          jurisdiction or country. Accordingly, those persons who choose to
          access the Site from other locations do so on their own initiative and
          are solely responsible for compliance with local laws, if and to the
          extent local laws are applicable.
        </p>
        <p>
          The Site is not tailored to comply with industry-specific regulations
          (Health Insurance Portability and Accountability Act (HIPAA), Federal
          Information Security Management Act (FISMA), etc.), so if your
          interactions would be subjected to such laws, you may not use this
          Site. You may not use the Site in a way that would violate the
          Gramm-Leach-Bliley Act (GLBA).
        </p>
        <p>
          The Site is intended for users who are at least 18 years old. Persons
          under the age of 18 are not permitted to use or register for the Site.
        </p>
        <h5>INTELLECTUAL PROPERTY RIGHTS</h5>
        <p>
          Unless otherwise indicated, the Site is our proprietary property and
          all source code, databases, functionality, software, website designs,
          audio, video, text, photographs, and graphics on the Site
          (collectively, the “Content”) and the trademarks, service marks, and
          logos contained therein (the “Marks”) are owned or controlled by us or
          licensed to us, and are protected by copyright and trademark laws and
          various other intellectual property rights and unfair competition laws
          of the United States, international copyright laws, and international
          conventions. The Content and the Marks are provided on the Site “AS
          IS” for your information and personal use only. Except as expressly
          provided in these Terms of Use, no part of the Site and no Content or
          Marks may be copied, reproduced, aggregated, republished, uploaded,
          posted, publicly displayed, encoded, translated, transmitted,
          distributed, sold, licensed, or otherwise exploited for any commercial
          purpose whatsoever, without our express prior written permission.
        </p>
        <p>
          Provided that you are eligible to use the Site, you are granted a
          limited license to access and use the Site and to download or print a
          copy of any portion of the Content to which you have properly gained
          access solely for your personal, non-commercial use. We reserve all
          rights not expressly granted to you in and to the Site, the Content
          and the Marks.
        </p>
        <h5>USER REPRESENTATIONS</h5>
        <p>
          By using the Site, you represent and warrant that: (1) all
          registration information you submit will be true, accurate, current,
          and complete; (2) you will maintain the accuracy of such information
          and promptly update such registration information as necessary; (3)
          you have the legal capacity and you agree to comply with these Terms
          of Use; (4) you are not a minor in the jurisdiction in which you
          reside; (5) you will not access the Site through automated or
          non-human means, whether through a bot, script, or otherwise; (6) you
          will not use the Site for any illegal or unauthorized purpose; and (7)
          your use of the Site will not violate any applicable law or
          regulation.
        </p>
        <p>
          If you provide any information that is untrue, inaccurate, not
          current, or incomplete, we have the right to suspend or terminate your
          account and refuse any and all current or future use of the Site (or
          any portion thereof).
        </p>
        <h5>USER REGISTRATION</h5>
        <p>
          You may be required to register with the Site. You agree to keep your
          password confidential and will be responsible for all use of your
          account and password. We reserve the right to remove, reclaim, or
          change a username you select if we determine, in our sole discretion,
          that such username is inappropriate, obscene, or otherwise
          objectionable.
        </p>
        <h5>PROHIBITED ACTIVITIES</h5>
        <p>
          You may not access or use the Site for any purpose other than that for
          which we make the Site available. The Site may not be used in
          connection with any commercial endeavors except those that are
          specifically endorsed or approved by us.
        </p>
        <h6>As a user of the Site, you agree not to:</h6>
        <p>
          1. Systematically retrieve data or other content from the Site to
          create or compile, directly or indirectly, a collection, compilation,
          database, or directory without written permission from us.
        </p>
        <p>
          2. Trick, defraud, or mislead us and other users, especially in any
          attempt to learn sensitive account information such as user passwords.
        </p>
        <p>
          3. Circumvent, disable, or otherwise interfere with security-related
          features of the Site, including features that prevent or restrict the
          use or copying of any Content or enforce limitations on the use of the
          Site and/or the Content contained therein.
        </p>
        <p>
          4. Disparage, tarnish, or otherwise harm, in our opinion, us and/or
          the Site.
        </p>
        <p>
          5. Use any information obtained from the Site in order to harass,
          abuse, or harm another person.
        </p>
        <p>
          6. Make improper use of our support services or submit false reports
          of abuse or misconduct.
        </p>
        <p>
          7. Use the Site in a manner inconsistent with any applicable laws or
          regulations.
        </p>
        <p>
          8. Upload or transmit (or attempt to upload or to transmit) viruses,
          Trojan horses, or other material, including excessive use of capital
          letters and spamming (continuous posting of repetitive text), that
          interferes with any party’s uninterrupted use and enjoyment of the
          Site or modifies, impairs, disrupts, alters, or interferes with the
          use, features, functions, operation, or maintenance of the Site.
        </p>
        <p>
          9. Engage in any automated use of the system, such as using scripts to
          send comments or messages, or using any data mining, robots, or
          similar data gathering and extraction tools.
        </p>
        <p>
          10. Delete the copyright or other proprietary rights notice from any
          Content.
        </p>
        <p>11. Sell or otherwise transfer your profile.</p>
        <p>
          12. Interfere with, disrupt, or create an undue burden on the Site or
          the networks or services connected to the Site.
        </p>
        <p>
          13. Harass, annoy, intimidate, or threaten any of our employees or
          agents engaged in providing any portion of the Site to you.
        </p>
        <p>
          14. Attempt to bypass any measures of the Site designed to prevent or
          restrict access to the Site, or any portion of the Site.
        </p>
        <p>
          15. Copy or adapt the Site’s software, including but not limited to
          Flash, PHP, HTML, JavaScript, or other code.
        </p>
        <p>
          16. Decipher, decompile, disassemble, or reverse engineer any of the
          software comprising or in any way making up a part of the Site.
        </p>
        <p>
          17. Except as may be the result of standard search engine or Internet
          browser usage, use, launch, develop, or distribute any automated
          system, including without limitation, any spider, robot, cheat
          utility, scraper, or offline reader that accesses the Site, or using
          or launching any unauthorized script or other software.
        </p>
        <p>
          18. Use a buying agent or purchasing agent to make purchases on the
          Site.
        </p>
        <p>
          19. Make any unauthorized use of the Site, including collecting
          usernames and/or email addresses of users by electronic or other means
          for the purpose of sending unsolicited email, or creating user
          accounts by automated means or under false pretenses.
        </p>
        <h5>USER GENERATED CONTRIBUTIONS</h5>
        <p>
          The Site may invite you to chat, contribute to, or participate in
          blogs, message boards, online forums, and other functionality, and may
          provide you with the opportunity to create, submit, post, display,
          transmit, perform, publish, distribute, or broadcast content and
          materials to us or on the Site, including but not limited to text,
          writings, video, audio, photographs, graphics, comments, suggestions,
          or personal information or other material (collectively,
          "Contributions"). Contributions may be viewable by other users of the
          Site and through third-party websites. As such, any Contributions you
          transmit may be treated as non-confidential and non-proprietary. When
          you create or make available any Contributions, you thereby represent
          and warrant that:
        </p>
        <p>
          1. The creation, distribution, transmission, public display, or
          performance, and the accessing, downloading, or copying of your
          Contributions do not and will not infringe the proprietary rights,
          including but not limited to the copyright, patent, trademark, trade
          secret, or moral rights of any third party.
        </p>
        <p>
          2. You are the creator and owner of or have the necessary licenses,
          rights, consents, releases, and permissions to use and to authorize
          us, the Site, and other users of the Site to use your Contributions in
          any manner contemplated by the Site and these Terms of Use.
        </p>
        <p>
          3. You have the written consent, release, and/or permission of each
          and every identifiable individual person in your Contributions to use
          the name or likeness of each and every such identifiable individual
          person to enable inclusion and use of your Contributions in any manner
          contemplated by the Site and these Terms of Use.
        </p>
        <p>4. Your Contributions are not false, inaccurate, or misleading.</p>
        <p>
          5. Your Contributions are not unsolicited or unauthorized advertising,
          promotional materials, pyramid schemes, chain letters, spam, mass
          mailings, or other forms of solicitation.
        </p>
        <p>
          6. Your Contributions are not obscene, lewd, lascivious, filthy,
          violent, harassing, libelous, slanderous, or otherwise objectionable
          (as determined by us).
        </p>
        <p>
          7. Your Contributions do not ridicule, mock, disparage, intimidate, or
          abuse anyone.
        </p>
        <p>
          8. Your Contributions are not used to harass or threaten (in the legal
          sense of those terms) any other person and to promote violence against
          a specific person or class of people.
        </p>
        <p>
          9. Your Contributions do not violate any applicable law, regulation,
          or rule.
        </p>
        <p>
          10. Your Contributions do not violate the privacy or publicity rights
          of any third party.
        </p>
        <p>
          11. Your Contributions do not contain any material that solicits
          personal information from anyone under the age of 18 or exploits
          people under the age of 18 in a sexual or violent manner.
        </p>
        <p>
          12. Your Contributions do not violate any applicable law concerning
          child pornography, or otherwise intended to protect the health or
          well-being of minors.
        </p>
        <p>
          13. Your Contributions do not include any offensive comments that are
          connected to race, national origin, gender, sexual preference, or
          physical handicap.
        </p>
        <p>
          14. Your Contributions do not otherwise violate, or link to material
          that violates, any provision of these Terms of Use, or any applicable
          law or regulation.
        </p>
        <p>
          Any use of the Site in violation of the foregoing violates these Terms
          of Use and may result in, among other things, termination or
          suspension of your rights to use the Site.
        </p>
        <h5>CONTRIBUTION LICENSE</h5>
        <p>
          By posting your Contributions to any part of the Site, you
          automatically grant, and you represent and warrant that you have the
          right to grant, to us an unrestricted, unlimited, irrevocable,
          perpetual, non-exclusive, transferable, royalty-free, fully-paid,
          worldwide right, and license to host, use, copy, reproduce, disclose,
          sell, resell, publish, broadcast, retitle, archive, store, cache,
          publicly perform, publicly display, reformat, translate, transmit,
          excerpt (in whole or in part), and distribute such Contributions
          (including, without limitation, your image and voice) for any purpose,
          commercial, advertising, or otherwise, and to prepare derivative works
          of, or incorporate into other works, such Contributions, and grant and
          authorize sublicenses of the foregoing. The use and distribution may
          occur in any media formats and through any media channels.
        </p>
        <p>
          This license will apply to any form, media, or technology now known or
          hereafter developed, and includes our use of your name, company name,
          and franchise name, as applicable, and any of the trademarks, service
          marks, trade names, logos, and personal and commercial images you
          provide. You waive all moral rights in your Contributions, and you
          warrant that moral rights have not otherwise been asserted in your
          Contributions.
        </p>
        <p>
          We do not assert any ownership over your Contributions. You retain
          full ownership of all of your Contributions and any intellectual
          property rights or other proprietary rights associated with your
          Contributions. We are not liable for any statements or representations
          in your Contributions provided by you in any area on the Site. You are
          solely responsible for your Contributions to the Site and you
          expressly agree to exonerate us from any and all responsibility and to
          refrain from any legal action against us regarding your Contributions.
        </p>
        <p>
          We have the right, in our sole and absolute discretion, (1) to edit,
          redact, or otherwise change any Contributions; (2) to re-categorize
          any Contributions to place them in more appropriate locations on the
          Site; and (3) to pre-screen or delete any Contributions at any time
          and for any reason, without notice. We have no obligation to monitor
          your Contributions.
        </p>
        <h5>SUBMISSIONS</h5>
        <p>
          You acknowledge and agree that any questions, comments, suggestions,
          ideas, feedback, or other information regarding the Site
          ("Submissions") provided by you to us are non-confidential and shall
          become our sole property. We shall own exclusive rights, including all
          intellectual property rights, and shall be entitled to the
          unrestricted use and dissemination of these Submissions for any lawful
          purpose, commercial or otherwise, without acknowledgment or
          compensation to you. You hereby waive all moral rights to any such
          Submissions, and you hereby warrant that any such Submissions are
          original with you or that you have the right to submit such
          Submissions. You agree there shall be no recourse against us for any
          alleged or actual infringement or misappropriation of any proprietary
          right in your Submissions.
        </p>
        <h5>THIRD-PARTY WEBSITE AND CONTENT</h5>
        <p>
          The Site may contain (or you may be sent via the Site) links to other
          websites ("Third-Party Websites") as well as articles, photographs,
          text, graphics, pictures, designs, music, sound, video, information,
          applications, software, and other content or items belonging to or
          originating from third parties ("Third-Party Content"). Such
          Third-Party Websites and Third-Party Content are not investigated,
          monitored, or checked for accuracy, appropriateness, or completeness
          by us, and we are not responsible for any Third-Party Websites
          accessed through the Site or any Third-Party Content posted on,
          available through, or installed from the Site, including the content,
          accuracy, offensiveness, opinions, reliability, privacy practices, or
          other policies of or contained in the Third-Party Websites or the
          Third-Party Content. Inclusion of, linking to, or permitting the use
          or installation of any Third-Party Websites or any Third-Party Content
          does not imply approval or endorsement thereof by us. If you decide to
          leave the Site and access the Third-Party Websites or to use or
          install any Third-Party Content, you do so at your own risk, and you
          should be aware these Terms of Use no longer govern. You should review
          the applicable terms and policies, including privacy and data
          gathering practices, of any website to which you navigate from the
          Site or relating to any applications you use or install from the Site.
          Any purchases you make through Third-Party Websites will be through
          other websites and from other companies, and we take no responsibility
          whatsoever in relation to such purchases which are exclusively between
          you and the applicable third party. You agree and acknowledge that we
          do not endorse the products or services offered on Third-Party
          Websites and you shall hold us harmless from any harm caused by your
          purchase of such products or services. Additionally, you shall hold us
          harmless from any losses sustained by you or harm caused to you
          relating to or resulting in any way from any Third-Party Content or
          any contact with Third-Party Websites.
        </p>
        <h5>ADVERTISERS</h5>
        <p>
          We allow advertisers to display their advertisements and other
          information in certain areas of the Site, such as sidebar
          advertisements or banner advertisements. If you are an advertiser, you
          shall take full responsibility for any advertisements you place on the
          Site and any services provided on the Site or products sold through
          those advertisements. Further, as an advertiser, you warrant and
          represent that you possess all rights and authority to place
          advertisements on the Site, including, but not limited to,
          intellectual property rights, publicity rights, and contractual
          rights. As an advertiser, you agree that such advertisements are
          subject to our Digital Millennium Copyright Act (“DMCA”) Notice and
          Policy provisions as described below, and you understand and agree
          there will be no refund or other compensation for DMCA
          takedown-related issues. We simply provide the space to place such
          advertisements, and we have no other relationship with advertisers.
        </p>
        <h5>SITE MANAGEMENT</h5>
        <p>
          We reserve the right, but not the obligation, to: (1) monitor the Site
          for violations of these Terms of Use; (2) take appropriate legal
          action against anyone who, in our sole discretion, violates the law or
          these Terms of Use, including without limitation, reporting such user
          to law enforcement authorities; (3) in our sole discretion and without
          limitation, refuse, restrict access to, limit the availability of, or
          disable (to the extent technologically feasible) any of your
          Contributions or any portion thereof; (4) in our sole discretion and
          without limitation, notice, or liability, to remove from the Site or
          otherwise disable all files and content that are excessive in size or
          are in any way burdensome to our systems; and (5) otherwise manage the
          Site in a manner designed to protect our rights and property and to
          facilitate the proper functioning of the Site.
        </p>
        <h5>PRIVACY POLICY</h5>
        <p>
          We care about data privacy and security. Please review our Privacy
          Policy: <a href="https://jci.cc/privacy">https://jci.cc/privacy</a>.
          By using the Site, you agree to be bound by our Privacy Policy, which
          is incorporated into these Terms of Use. Please be advised the Site is
          hosted in the United States. If you access the Site from any other
          region of the world with laws or other requirements governing personal
          data collection, use, or disclosure that differ from applicable laws
          in the United States, then through your continued use of the Site, you
          are transferring your data to the United States, and you agree to have
          your data transferred to and processed in the United States.
        </p>
        <h5>DIGITAL MILLENNIUM COPYRIGHT ACT (DMCA) NOTICE AND POLICY</h5>
        <h6>Notifications</h6>
        <p>
          We respect the intellectual property rights of others. If you believe
          that any material available on or through the Site infringes upon any
          copyright you own or control, please immediately notify our Designated
          Copyright Agent using the contact information provided below (a
          “Notification”). A copy of your Notification will be sent to the
          person who posted or stored the material addressed in the
          Notification. Please be advised that pursuant to federal law you may
          be held liable for damages if you make material misrepresentations in
          a Notification. Thus, if you are not sure that material located on or
          linked to by the Site infringes your copyright, you should consider
          first contacting an attorney.
        </p>
        <p>
          All Notifications should meet the requirements of DMCA 17 U.S.C. §
          512(c)(3) and include the following information: (1) A physical or
          electronic signature of a person authorized to act on behalf of the
          owner of an exclusive right that is allegedly infringed; (2)
          identification of the copyrighted work claimed to have been infringed,
          or, if multiple copyrighted works on the Site are covered by the
          Notification, a representative list of such works on the Site; (3)
          identification of the material that is claimed to be infringing or to
          be the subject of infringing activity and that is to be removed or
          access to which is to be disabled, and information reasonably
          sufficient to permit us to locate the material; (4) information
          reasonably sufficient to permit us to contact the complaining party,
          such as an address, telephone number, and, if available, an email
          address at which the complaining party may be contacted; (5) a
          statement that the complaining party has a good faith belief that use
          of the material in the manner complained of is not authorized by the
          copyright owner, its agent, or the law; and (6) a statement that the
          information in the notification is accurate, and under penalty of
          perjury, that the complaining party is authorized to act on behalf of
          the owner of an exclusive right that is allegedly infringed upon.
        </p>
        <h5>Counter Notification</h5>
        <p>
          If you believe your own copyrighted material has been removed from the
          Site as a result of a mistake or misidentification, you may submit a
          written counter notification to [us/our Designated Copyright Agent]
          using the contact information provided below (a “Counter
          Notification”). To be an effective Counter Notification under the
          DMCA, your Counter Notification must include substantially the
          following: (1) identification of the material that has been removed or
          disabled and the location at which the material appeared before it was
          removed or disabled; (2) a statement that you consent to the
          jurisdiction of the Federal District Court in which your address is
          located, or if your address is outside the United States, for any
          judicial district in which we are located; (3) a statement that you
          will accept service of process from the party that filed the
          Notification or the party's agent; (4) your name, address, and
          telephone number; (5) a statement under penalty of perjury that you
          have a good faith belief that the material in question was removed or
          disabled as a result of a mistake or misidentification of the material
          to be removed or disabled; and (6) your physical or electronic
          signature.
        </p>
        <p>
          If you send us a valid, written Counter Notification meeting the
          requirements described above, we will restore your removed or disabled
          material, unless we first receive notice from the party filing the
          Notification informing us that such party has filed a court action to
          restrain you from engaging in infringing activity related to the
          material in question. Please note that if you materially misrepresent
          that the disabled or removed content was removed by mistake or
          misidentification, you may be liable for damages, including costs and
          attorney's fees. Filing a false Counter Notification constitutes
          perjury.
        </p>
        <p>Designated Copyright Agent</p>
        <p>Kevin Hin</p>
        <p>Attn: Copyright Agent</p>
        <p>15645 Olive Blvd</p>
        <p>Chesterfield, MO 63017</p>
        <p>United States</p>
        <p>copyright@jci.cc</p>
        <h5>TERM AND TERMINATION</h5>
        <p>
          These Terms of Use shall remain in full force and effect while you use
          the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE,
          WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
          LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING
          CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON,
          INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
          WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY
          APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR
          PARTICIPATION IN THE SITE OR DELETE YOUR ACCOUNT AND ANY CONTENT OR
          INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE
          DISCRETION.
        </p>
        <p>
          If we terminate or suspend your account for any reason, you are
          prohibited from registering and creating a new account under your
          name, a fake or borrowed name, or the name of any third party, even if
          you may be acting on behalf of the third party. In addition to
          terminating or suspending your account, we reserve the right to take
          appropriate legal action, including without limitation pursuing civil,
          criminal, and injunctive redress.
        </p>
        <h5>MODIFICATIONS AND INTERRUPTIONS</h5>
        <p>
          We reserve the right to change, modify, or remove the contents of the
          Site at any time or for any reason at our sole discretion without
          notice. However, we have no obligation to update any information on
          our Site. We also reserve the right to modify or discontinue all or
          part of the Site without notice at any time. We will not be liable to
          you or any third party for any modification, price change, suspension,
          or discontinuance of the Site.
        </p>
        <p>
          We cannot guarantee the Site will be available at all times. We may
          experience hardware, software, or other problems or need to perform
          maintenance related to the Site, resulting in interruptions, delays,
          or errors. We reserve the right to change, revise, update, suspend,
          discontinue, or otherwise modify the Site at any time or for any
          reason without notice to you. You agree that we have no liability
          whatsoever for any loss, damage, or inconvenience caused by your
          inability to access or use the Site during any downtime or
          discontinuance of the Site. Nothing in these Terms of Use will be
          construed to obligate us to maintain and support the Site or to supply
          any corrections, updates, or releases in connection therewith.
        </p>
        <h5>GOVERNING LAW</h5>
        <p>
          These Terms of Use and your use of the Site are governed by and
          construed in accordance with the laws of the State of Missouri
          applicable to agreements made and to be entirely performed within the
          State of Missouri, without regard to its conflict of law principles.
        </p>
        <h6>DISPUTE RESOLUTION</h6>
        <h6>Informal Negotiations</h6>
        <p>
          To expedite resolution and control the cost of any dispute,
          controversy, or claim related to these Terms of Use (each a "Dispute"
          and collectively, the “Disputes”) brought by either you or us
          (individually, a “Party” and collectively, the “Parties”), the Parties
          agree to first attempt to negotiate any Dispute (except those Disputes
          expressly provided below) informally for at least thirty (30) days
          before initiating arbitration. Such informal negotiations commence
          upon written notice from one Party to the other Party.
        </p>
        <h5>Binding Arbitration</h5>
        <p>
          If the Parties are unable to resolve a Dispute through informal
          negotiations, the Dispute (except those Disputes expressly excluded
          below) will be finally and exclusively resolved through binding
          arbitration. YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD
          HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. The arbitration
          shall be commenced and conducted under the Commercial Arbitration
          Rules of the American Arbitration Association ("AAA") and, where
          appropriate, the AAA’s Supplementary Procedures for Consumer Related
          Disputes ("AAA Consumer Rules"), both of which are available at the
          AAA website: www.adr.org. Your arbitration fees and your share of
          arbitrator compensation shall be governed by the AAA Consumer Rules
          and, where appropriate, limited by the AAA Consumer Rules. The
          arbitration may be conducted in person, through the submission of
          documents, by phone, or online. The arbitrator will make a decision in
          writing, but need not provide a statement of reasons unless requested
          by either Party. The arbitrator must follow applicable law, and any
          award may be challenged if the arbitrator fails to do so. Except where
          otherwise required by the applicable AAA rules or applicable law, the
          arbitration will take place in Saint Louis, Missouri. Except as
          otherwise provided herein, the Parties may litigate in court to compel
          arbitration, stay proceedings pending arbitration, or to confirm,
          modify, vacate, or enter judgment on the award entered by the
          arbitrator.
        </p>
        <p>
          If for any reason, a Dispute proceeds in court rather than
          arbitration, the Dispute shall be commenced or prosecuted in the state
          and federal courts located in Saint Louis, Missouri, and the Parties
          hereby consent to, and waive all defenses of lack of personal
          jurisdiction, and forum non conveniens with respect to venue and
          jurisdiction in such state and federal courts. Application of the
          United Nations Convention on Contracts for the International Sale of
          Goods and the Uniform Computer Information Transaction Act (UCITA) is
          excluded from these Terms of Use.
        </p>
        <p>
          In no event shall any Dispute brought by either Party related in any
          way to the Site be commenced more than one (1) years after the cause
          of action arose. If this provision is found to be illegal or
          unenforceable, then neither Party will elect to arbitrate any Dispute
          falling within that portion of this provision found to be illegal or
          unenforceable, and such Dispute shall be decided by a court of
          competent jurisdiction within the courts listed for jurisdiction
          above, and the Parties agree to submit to the personal jurisdiction of
          that court.
        </p>
        <h5>Restrictions</h5>
        <p>
          The Parties agree that any arbitration shall be limited to the Dispute
          between the Parties individually. To the full extent permitted by law,
          (a) no arbitration shall be joined with any other proceeding; (b)
          there is no right or authority for any Dispute to be arbitrated on a
          class-action basis or to utilize class action procedures; and (c)
          there is no right or authority for any Dispute to be brought in a
          purported representative capacity on behalf of the general public or
          any other persons.
        </p>
        <h5>Exceptions to Informal Negotiations and Arbitration</h5>
        <p>
          The Parties agree that the following Disputes are not subject to the
          above provisions concerning informal negotiations and binding
          arbitration: (a) any Disputes seeking to enforce or protect, or
          concerning the validity of, any of the intellectual property rights of
          a Party; (b) any Dispute related to, or arising from, allegations of
          theft, piracy, invasion of privacy, or unauthorized use; and (c) any
          claim for injunctive relief. If this provision is found to be illegal
          or unenforceable, then neither Party will elect to arbitrate any
          Dispute falling within that portion of this provision found to be
          illegal or unenforceable and such Dispute shall be decided by a court
          of competent jurisdiction within the courts listed for jurisdiction
          above, and the Parties agree to submit to the personal jurisdiction of
          that court.
        </p>
        <h5>CORRECTIONS</h5>
        <p>
          There may be information on the Site that contains typographical
          errors, inaccuracies, or omissions, including descriptions, pricing,
          availability, and various other information. We reserve the right to
          correct any errors, inaccuracies, or omissions and to change or update
          the information on the Site at any time, without prior notice.
        </p>
        <h5>DISCLAIMER</h5>
        <p>
          THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
          THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK.
          TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
          EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF,
          INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
          ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY
          WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR
          RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
          CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY
          NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE,
          (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY
          AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
          THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM
          THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY
          BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6)
          ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS
          OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT
          POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO
          NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
          PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE
          SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION
          FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A
          PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION
          BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS
          WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY
          ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION
          WHERE APPROPRIATE.
        </p>
        <h5>LIMITATIONS OF LIABILITY</h5>
        <p>
          IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
          TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
          EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
          PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR
          USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
          SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED
          HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS
          OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE LESSER
          OF THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE SIX (6) MONTH
          PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING OR $500.00 USD. CERTAIN US
          STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED
          WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE
          LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS
          MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
        </p>
        <h5>INDEMNIFICATION</h5>
        <p>
          You agree to defend, indemnify, and hold us harmless, including our
          subsidiaries, affiliates, and all of our respective officers, agents,
          partners, and employees, from and against any loss, damage, liability,
          claim, or demand, including reasonable attorneys’ fees and expenses,
          made by any third party due to or arising out of: (1) your
          Contributions; (2) use of the Site; (3) breach of these Terms of Use;
          (4) any breach of your representations and warranties set forth in
          these Terms of Use; (5) your violation of the rights of a third party,
          including but not limited to intellectual property rights; or (6) any
          overt harmful act toward any other user of the Site with whom you
          connected via the Site. Notwithstanding the foregoing, we reserve the
          right, at your expense, to assume the exclusive defense and control of
          any matter for which you are required to indemnify us, and you agree
          to cooperate, at your expense, with our defense of such claims. We
          will use reasonable efforts to notify you of any such claim, action,
          or proceeding which is subject to this indemnification upon becoming
          aware of it.
        </p>
        <h5>USER DATA</h5>
        <p>
          We will maintain certain data that you transmit to the Site for the
          purpose of managing the performance of the Site, as well as data
          relating to your use of the Site. Although we perform regular routine
          backups of data, you are solely responsible for all data that you
          transmit or that relates to any activity you have undertaken using the
          Site. You agree that we shall have no liability to you for any loss or
          corruption of any such data, and you hereby waive any right of action
          against us arising from any such loss or corruption of such data.
        </p>
        <h5>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h5>
        <p>
          Visiting the Site, sending us emails, and completing online forms
          constitute electronic communications. You consent to receive
          electronic communications, and you agree that all agreements, notices,
          disclosures, and other communications we provide to you
          electronically, via email and on the Site, satisfy any legal
          requirement that such communication be in writing. YOU HEREBY AGREE TO
          THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
          RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS
          OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE. You
          hereby waive any rights or requirements under any statutes,
          regulations, rules, ordinances, or other laws in any jurisdiction
          which require an original signature or delivery or retention of
          non-electronic records, or to payments or the granting of credits by
          any means other than electronic means.
        </p>
        <h5>CALIFORNIA USERS AND RESIDENTS</h5>
        <p>
          If any complaint with us is not satisfactorily resolved, you can
          contact the Complaint Assistance Unit of the Division of Consumer
          Services of the California Department of Consumer Affairs in writing
          at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834
          or by telephone at (800) 952-5210 or (916) 445-1254.
        </p>
        <h5>MISCELLANEOUS</h5>
        <p>
          These Terms of Use and any policies or operating rules posted by us on
          the Site or in respect to the Site constitute the entire agreement and
          understanding between you and us. Our failure to exercise or enforce
          any right or provision of these Terms of Use shall not operate as a
          waiver of such right or provision. These Terms of Use operate to the
          fullest extent permissible by law. We may assign any or all of our
          rights and obligations to others at any time. We shall not be
          responsible or liable for any loss, damage, delay, or failure to act
          caused by any cause beyond our reasonable control. If any provision or
          part of a provision of these Terms of Use is determined to be
          unlawful, void, or unenforceable, that provision or part of the
          provision is deemed severable from these Terms of Use and does not
          affect the validity and enforceability of any remaining provisions.
          There is no joint venture, partnership, employment or agency
          relationship created between you and us as a result of these Terms of
          Use or use of the Site. You agree that these Terms of Use will not be
          construed against us by virtue of having drafted them. You hereby
          waive any and all defenses you may have based on the electronic form
          of these Terms of Use and the lack of signing by the parties hereto to
          execute these Terms of Use.
        </p>
        <h5>CONTACT US</h5>
        <p>
          In order to resolve a complaint regarding the Site or to receive
          further information regarding use of the Site, please contact us at:
        </p>
        <h6>Junior Chamber International, Inc.</h6>
        <h6>15645 Olive Blvd</h6>
        <h6>Chesterfield, MO 63017</h6>
        <h6>United States</h6>
        <h6>Phone: (US)(636) 449-3100</h6>
        <h6>Fax: (US)(636) 449-3107</h6>
        <h6>info@jci.cc</h6>
      </div>
    </b-modal>
    <b-modal id="privacy" hide-footer title="PRIVACY NOTICE">
      <div>
        <h5>PRIVACY NOTICE</h5>
        <h6>Last updated March 24, 2021</h6>
        <p>
          Thank you for choosing to be part of our community at Junior Chamber
          International, Inc., doing business as JCI ("JCI", "we", "us", "our").
          We are committed to protecting your personal information and your
          right to privacy. If you have any questions or concerns about this
          privacy notice, or our practices with regards to your personal
          information, please contact us at info@jci.cc.
        </p>
        <p>
          When you visit our website
          <a href="https://jci.cc">https://jci.cc</a>(the "Website") , and more
          generally, use any of our services (the "Services", which include the
          Website), we appreciate that you are trusting us with your personal
          information. We take your privacy very seriously. In this privacy
          notice, we seek to explain to you in the clearest way possible what
          information we collect, how we use it and what rights you have in
          relation to it. We hope you take some time to read through it
          carefully, as it is important. If there are any terms in this privacy
          notice that you do not agree with, please discontinue use of our
          Services immediately.
        </p>
        <p>
          This privacy notice applies to all information collected through our
          Services (which, as described above, includes our Website), as well
          as, any related services, sales, marketing or events.
        </p>
        <p>
          Please read this privacy notice carefully as it will help you
          understand what we do with the information that we collect.
        </p>
        <h5>TABLE OF CONTENTS</h5>
        <h6>1. WHAT INFORMATION DO WE COLLECT?</h6>
        <h6>2. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h6>
        <h6>3. WHO WILL YOUR INFORMATION BE SHARED WITH?</h6>
        <h6>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h6>
        <h6>5. DO WE USE GOOGLE MAPS PLATFORM APIS?</h6>
        <h6>6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h6>
        <h6>7. HOW LONG DO WE KEEP YOUR INFORMATION?</h6>
        <h6>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h6>
        <h6>9. DO WE COLLECT INFORMATION FROM MINORS?</h6>
        <h6>10. WHAT ARE YOUR PRIVACY RIGHTS?</h6>
        <h6>11. CONTROLS FOR DO-NOT-TRACK FEATURES</h6>
        <h6>12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h6>
        <h6>13. DO WE MAKE UPDATES TO THIS NOTICE?</h6>
        <h6>14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h6>
        <h6>
          15. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?
        </h6>

        <h5>1. WHAT INFORMATION DO WE COLLECT?</h5>
        <h6>Personal information you disclose to us</h6>
        <p>In Short: We collect personal information that you provide to us.</p>
        <p>
          We collect personal information that you voluntarily provide to us
          when you register on the Website, express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the Website (such as by posting messages
          in our online forums or entering competitions, contests or giveaways)
          or otherwise when you contact us.
        </p>
        <p>
          The personal information that we collect depends on the context of
          your interactions with us and the Website, the choices you make and
          the products and features you use. The personal information we collect
          may include the following:
        </p>
        <p>
          Personal Information Provided by You. We collect names; phone numbers;
          email addresses; mailing addresses; usernames; passwords; contact
          preferences; contact or authentication data; billing addresses;
          debit/credit card numbers; date of birth; gender; and other similar
          information.
        </p>
        <p>
          Payment Data. We may collect data necessary to process your payment if
          you make purchases, such as your payment instrument number (such as a
          credit card number), and the security code associated with your
          payment instrument. All payment data is stored by Total System
          Services, LLC and Cybersource. You may find their privacy notice
          link(s) here:
          <a href="https://www.tsys.com/privacy-policy.html"
            >https://www.tsys.com/privacy-policy.html</a
          >
          and
          <a href="https://usa.visa.com/legal/privacy-policy.html"
            >https://usa.visa.com/legal/privacy-policy.html</a
          >
          .
        </p>
        <p>
          All personal information that you provide to us must be true, complete
          and accurate, and you must notify us of any changes to such personal
          information.
        </p>
        <h5>Information automatically collected</h5>
        <p>
          In Short: Some information — such as your Internet Protocol (IP)
          address and/or browser and device characteristics — is collected
          automatically when you visit our Website.
        </p>
        <p>
          We automatically collect certain information when you visit, use or
          navigate the Website. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Website and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Website, and for our internal analytics and reporting purposes.
        </p>
        <p>
          Like many businesses, we also collect information through cookies and
          similar technologies.
        </p>
        <ul>
          <li>The information we collect includes:</li>
          <li>
            ■Log and Usage Data. Log and usage data is service-related,
            diagnostic, usage and performance information our servers
            automatically collect when you access or use our Website and which
            we record in log files. Depending on how you interact with us, this
            log data may include your IP address, device information, browser
            type and settings and information about your activity in the Website
            (such as the date/time stamps associated with your usage, pages and
            files viewed, searches and other actions you take such as which
            features you use), device event information (such as system
            activity, error reports (sometimes called 'crash dumps') and
            hardware settings).
          </li>
          <li>
            ■Device Data. We collect device data such as information about your
            computer, phone, tablet or other device you use to access the
            Website. Depending on the device used, this device data may include
            information such as your IP address (or proxy server), device and
            application identification numbers, location, browser type, hardware
            model Internet service provider and/or mobile carrier, operating
            system and system configuration information.
          </li>
        </ul>
        <h5>2. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h5>
        <p>
          In Short: We only share information with your consent, to comply with
          laws, to provide you with services, to protect your rights, or to
          fulfill business obligations.
        </p>
        <ul>
          <li>
            We may process or share your data that we hold based on the
            following legal basis:
          </li>
          <li>
            ■Consent: We may process your data if you have given us specific
            consent to use your personal information for a specific purpose.
          </li>
          <li>
            ■Legitimate Interests: We may process your data when it is
            reasonably necessary to achieve our legitimate business interests.
          </li>
          <li>
            ■Performance of a Contract: Where we have entered into a contract
            with you, we may process your personal information to fulfill the
            terms of our contract.
          </li>
          <li>
            ■Legal Obligations: We may disclose your information where we are
            legally required to do so in order to comply with applicable law,
            governmental requests, a judicial proceeding, court order, or legal
            process, such as in response to a court order or a subpoena
            (including in response to public authorities to meet national
            security or law enforcement requirements).
          </li>
          <li>
            ■Vital Interests: We may disclose your information where we believe
            it is necessary to investigate, prevent, or take action regarding
            potential violations of our policies, suspected fraud, situations
            involving potential threats to the safety of any person and illegal
            activities, or as evidence in litigation in which we are involved.
          </li>
        </ul>
        <ul>
          <li>
            More specifically, we may need to process your data or share your
            personal information in the following situations:
          </li>
          <li>
            ■Business Transfers. We may share or transfer your information in
            connection with, or during negotiations of, any merger, sale of
            company assets, financing, or acquisition of all or a portion of our
            business to another company.
          </li>
          <li>
            ■Vendors, Consultants and Other Third-Party Service Providers. We
            may share your data with third-party vendors, service providers,
            contractors or agents who perform services for us or on our behalf
            and require access to such information to do that work. Examples
            include: payment processing, data analysis, email delivery, hosting
            services, customer service and marketing efforts. We may allow
            selected third parties to use tracking technology on the Website,
            which will enable them to collect data on our behalf about how you
            interact with our Website over time. This information may be used
            to, among other things, analyze and track data, determine the
            popularity of certain content, pages or features, and better
            understand online activity. Unless described in this notice, we do
            not share, sell, rent or trade any of your information with third
            parties for their promotional purposes. We have contracts in place
            with our data processors, which are designed to help safeguard your
            personal information. This means that they cannot do anything with
            your personal information unless we have instructed them to do it.
            They will also not share your personal information with any
            organization apart from us. They also commit to protect the data
            they hold on our behalf and to retain it for the period we instruct.
          </li>
          <li>
            ■Third-Party Advertisers. We may use third-party advertising
            companies to serve ads when you visit or use the Website. These
            companies may use information about your visits to our Website(s)
            and other websites that are contained in web cookies and other
            tracking technologies in order to provide advertisements about goods
            and services of interest to you.
          </li>
          <li>
            ■Other Users. When you share personal information (for example, by
            posting comments, contributions or other content to the Website) or
            otherwise interact with public areas of the Website, such personal
            information may be viewed by all users and may be publicly made
            available outside the Website in perpetuity. Similarly, other users
            will be able to view descriptions of your activity, communicate with
            you within our Website, and view your profile.
          </li>
        </ul>
        <h5>3. WHO WILL YOUR INFORMATION BE SHARED WITH?</h5>
        <p>
          In Short: We only share information with the following third parties.
        </p>
        <p>
          We only share and disclose your information with the following third
          parties. We have categorized each party so that you may be easily
          understand the purpose of our data collection and processing
          practices. If we have processed your data based on your consent and
          you wish to revoke your consent, please contact us using the contact
          details provided in the section below titled "HOW CAN YOU CONTACT US
          ABOUT THIS NOTICE?".
        </p>
        <ul>
          <li>
            <h6>■Cloud Computing Services</h6>
          </li>
          <li>Google Cloud Platform and Amazon Web Services (AWS)</li>
          <li>
            <h6>■Data Backup and Security</h6>
          </li>
          <li>Google Drive Backup</li>
          <li>
            <h6>■Functionality and Infrastructure Optimization</h6>
          </li>
          <li>Google Cloud Storage</li>
          <li>
            <h6>■Social Media Sharing and Advertising</h6>
          </li>
          <li>Facebook social plugins and Twitter social plugins</li>
          <li>
            <h6>■User Account Registration and Authentication</h6>
          </li>
          <li>Google Sign-In</li>
          <li>
            <h6>■Web and Mobile Analytics</h6>
          </li>
          <li>Google Analytics</li>
          <li>
            <h6>■Website Testing</h6>
          </li>
          <li>Google Website Optimizer</li>
        </ul>
        <h5>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h5>
        <p>
          In Short: We may use cookies and other tracking technologies to
          collect and store your information.
        </p>
        <p>
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to access or store information. Specific information about
          how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Notice.
        </p>
        <h5>5. DO WE USE GOOGLE MAPS PLATFORM APIS?</h5>
        <p>
          In Short: Yes, we use Google Maps Platform APIs for the purpose of
          providing better service.
        </p>
        <p>
          This Website uses Google Maps Platform APIs which are subject to
          Google’s Terms of Service. You may find the Google Maps Platform Terms
          of Service here. To find out more about Google’s Privacy Policy,
          please refer to this link.
        </p>
        <h5>6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h5>
        <p>
          In Short: We may transfer, store, and process your information in
          countries other than your own.
        </p>
        <p>
          Our servers are located in United States. If you are accessing our
          Website from outside United States, please be aware that your
          information may be transferred to, stored, and processed by us in our
          facilities and by those third parties with whom we may share your
          personal information (see "WILL YOUR INFORMATION BE SHARED WITH
          ANYONE?" above), in United States, and other countries.
        </p>
        <p>
          If you are a resident in the European Economic Area, then these
          countries may not necessarily have data protection laws or other
          similar laws as comprehensive as those in your country. We will
          however take all necessary measures to protect your personal
          information in accordance with this privacy notice and applicable law.
        </p>
        <p>European Commission's Standard Contractual Clauses:</p>
        <p>
          We have implemented measures to protect your personal information,
          including by using the European Commission's Standard Contractual
          Clauses for transfers of personal information between our group
          companies and between us and our third-party providers. These clauses
          require all recipients to protect all personal information that they
          process originating from the EEA in accordance with European data
          protection laws and regulations. Our Standard Contractual Clauses can
          be provided upon request. We have implemented similar appropriate
          safeguards with our third-party service providers and partners and
          further details can be provided upon request.
        </p>
        <h5>7. HOW LONG DO WE KEEP YOUR INFORMATION?</h5>
        <p>
          In Short: We keep your information for as long as necessary to fulfill
          the purposes outlined in this privacy notice unless otherwise required
          by law.
        </p>
        <p>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than the
          period of time in which users have an account with us.
        </p>
        <p>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>
        <h5>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h5>
        <p>
          In Short: We aim to protect your personal information through a system
          of organizational and technical security measures.
        </p>
        <p>
          We have implemented appropriate technical and organizational security
          measures designed to protect the security of any personal information
          we process. However, despite our safeguards and efforts to secure your
          information, no electronic transmission over the Internet or
          information storage technology can be guaranteed to be 100% secure, so
          we cannot promise or guarantee that hackers, cybercriminals, or other
          unauthorized third parties will not be able to defeat our security,
          and improperly collect, access, steal, or modify your information.
          Although we will do our best to protect your personal information,
          transmission of personal information to and from our Website is at
          your own risk. You should only access the Website within a secure
          environment.
        </p>
        <h5>9. DO WE COLLECT INFORMATION FROM MINORS?</h5>
        <p>
          In Short: We do not knowingly collect data from or market to children
          under 18 years of age.
        </p>
        <p>
          We do not knowingly solicit data from or market to children under 18
          years of age. By using the Website, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent’s use of the Website. If we learn that
          personal information from users less than 18 years of age has been
          collected, we will deactivate the account and take reasonable measures
          to promptly delete such data from our records. If you become aware of
          any data we may have collected from children under age 18, please
          contact us at privacy@jci.cc.
        </p>
        <h5>10. WHAT ARE YOUR PRIVACY RIGHTS?</h5>
        <p>
          In Short: In some regions, such as the European Economic Area, you
          have rights that allow you greater access to and control over your
          personal information. You may review, change, or terminate your
          account at any time.
        </p>
        <p>
          In some regions (like the European Economic Area), you have certain
          rights under applicable data protection laws. These may include the
          right (i) to request access and obtain a copy of your personal
          information, (ii) to request rectification or erasure; (iii) to
          restrict the processing of your personal information; and (iv) if
          applicable, to data portability. In certain circumstances, you may
          also have the right to object to the processing of your personal
          information. To make such a request, please use the contact details
          provided below. We will consider and act upon any request in
          accordance with applicable data protection laws.
        </p>
        <p>
          If we are relying on your consent to process your personal
          information, you have the right to withdraw your consent at any time.
          Please note however that this will not affect the lawfulness of the
          processing before its withdrawal, nor will it affect the processing of
          your personal information conducted in reliance on lawful processing
          grounds other than consent.
        </p>
        <p>
          If you are a resident in the European Economic Area and you believe we
          are unlawfully processing your personal information, you also have the
          right to complain to your local data protection supervisory authority.
          You can find their contact details here:
          <a
            href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html"
            >http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html</a
          >
        </p>
        <p>
          If you are a resident in Switzerland, the contact details for the data
          protection authorities are available here:
          <a href="https://www.edoeb.admin.ch/edoeb/en/home.html"
            >https://www.edoeb.admin.ch/edoeb/en/home.html</a
          >
          .
        </p>
        <p>
          If you have questions or comments about your privacy rights, you may
          email us at privacy@jci.cc.
        </p>
        <h5>Account Information</h5>
        <p>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </p>
        <ul>
          <li>
            ■Log in to your account settings and update your user account.
          </li>
          <li>■Contact us using the contact information provided.</li>
        </ul>
        <p>
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          Terms of Use and/or comply with applicable legal requirements.
        </p>
        <p>
          Cookies and similar technologies: Most Web browsers are set to accept
          cookies by default. If you prefer, you can usually choose to set your
          browser to remove cookies and to reject cookies. If you choose to
          remove cookies or reject cookies, this could affect certain features
          or services of our Website. To opt-out of interest-based advertising
          by advertisers on our Website visit
          <a href="http://www.aboutads.info/choices/"
            >http://www.aboutads.info/choices/</a
          >.
        </p>
        <p>
          Opting out of email marketing: You can unsubscribe from our marketing
          email list at any time by clicking on the unsubscribe link in the
          emails that we send or by contacting us using the details provided
          below. You will then be removed from the marketing email list —
          however, we may still communicate with you, for example to send you
          service-related emails that are necessary for the administration and
          use of your account, to respond to service requests, or for other
          non-marketing purposes. To otherwise opt-out, you may:
        </p>
        <ul>
          <li>■Access your account settings and update your preferences.</li>
          <li>■Contact us using the contact information provided.</li>
        </ul>
        <h5>11. CONTROLS FOR DO-NOT-TRACK FEATURES</h5>
        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ("DNT") feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </p>
        <h5>12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h5>
        <p>
          In Short: Yes, if you are a resident of California, you are granted
          specific rights regarding access to your personal information.
        </p>
        <p>
          California Civil Code Section 1798.83, also known as the "Shine The
          Light" law, permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us using the contact information provided below.
        </p>
        <p>
          If you are under 18 years of age, reside in California, and have a
          registered account with the Website, you have the right to request
          removal of unwanted data that you publicly post on the Website. To
          request removal of such data, please contact us using the contact
          information provided below, and include the email address associated
          with your account and a statement that you reside in California. We
          will make sure the data is not publicly displayed on the Website, but
          please be aware that the data may not be completely or comprehensively
          removed from all our systems (e.g. backups, etc.).
        </p>
        <h5>13. DO WE MAKE UPDATES TO THIS NOTICE?</h5>
        <p>
          In Short: Yes, we will update this notice as necessary to stay
          compliant with relevant laws.
        </p>
        <p>
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated "Revised" date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy notice
          frequently to be informed of how we are protecting your information.
        </p>
        <h5>14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h5>
        <p>
          If you have questions or comments about this notice, you may email us
          at privacy@jci.cc or by post to:
        </p>
        <p>Junior Chamber International, Inc.</p>
        <h6>15645 Olive Blvd</h6>
        <h6>Chesterfield, MO 63017, USA</h6>
        <h6>United States</h6>
        <h5>
          15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </h5>
        <p>
          Based on the applicable laws of your country, you may have the right
          to request access to the personal information we collect from you,
          change that information, or delete it in some circumstances. To
          request to review, update, or delete your personal information, please
          visit: privacy@jci.cc. We will respond to your request within 30 days.
        </p>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "SignUp1",
  data() {
    return {
      isVerified: false, // 验证是否通过
      confirmPassword: "", // 确认框输入的密码
      termsAndConditions: false,
      privacyPolicy: false,
      showTips: false,
      sexList: ["Female", "Male", "Prefer not to Say", "Other"],
      // 表单数据
      form: {
        first_name: "", // 姓
        last_name: "", // 名
        password: "", // 密码
        email: "", // 邮箱
        nation_id: "", // 国家
        province_id: "", // 省
        organization_id: "", // 组织id
        birthday: "",
      },
      organ: {},
      organList: [],
      regional: {},
      regionalList: [],
      local: "",
      localList: [],
      sex: "",
      // 出生地区
      countryList: [],
      regionList: [],

      alreadyEmail:"",//邮箱已注册
      registered: false,
    };
  },
  computed: {
    // 日历插件的默认日期
    DefaultDate() {
      let date = new Date();
      let y = date.getFullYear() - 15;
      return new Date(`${y}-01-01`);
    },
  },
  created() {
    this.getNation(2);
    this.getOrganizationList(2);
  },
  methods: {
    // 密码验证
    passwordValidation() {
      if (this.form.password !== this.confirmPassword) {
        this.isVerified = true;
      } else {
        this.isVerified = false;
      }
    },
    tips() {
      this.showTips = true;
    },
    tipdown() {
      this.showTips = false;
    },
    getNation(level) {
      const data = {
        level: level,
      };
      this.$http.getAddress(data).then((res) => {
        if (res.status == 200) {
          this.countryList = res.data;
          // console.log("国家", this.countryList);
        }
      });
    },
    // 获取地区
    getRegion(parent_id) {
      const data = {
        parent_id: parent_id,
      };
      this.$http.getAddress(data).then((res) => {
        if (res.status == 200) {
          this.regionList = res.data;
          // console.log("地区", this.regionList);
        }
      });
    },
    // 出生国家列表选中
    handleChangeCountry(parent_id) {
      this.getRegion(parent_id);
    },
    // 获取组织列表
    getOrganizationList(type) {
      this.$http
        .getOrganizationList({
          type,
        })
        .then((res) => {
          if (res.status == 200) {
            this.organList = res.data;
          }
        });
    },
    getSonOrgan(type, parent) {
      this.$http
        .getOrganizationList({
          parent_id: parent.id,
          type
        })
        .then((res) => {
          if (res.status == 200) {
            if (type == 3) {
              this.regionalList = res.data;
            } else {
              this.localList = res.data;
            }
          }
        });
    },

    // 注册
    signUp() {
      this.registered = false;
      if (this.organ) {
        if (this.regionalList.length == 0 && this.localList.length == 0) {
          this.form.organization_id = "";
          if (
            this.form.first_name !== "" &&
            this.form.last_name !== "" &&
            this.form.password !== "" &&
            this.form.email !== "" &&
            this.form.birthday !== "" &&
            this.form.nation_id !== "" &&
            this.sex !== ""
          ) {
            if (this.form.password == this.confirmPassword) {
              this.$http
                .userRegister({
                  email: this.form.email,
                  password: this.form.password,
                  first_name: this.form.first_name,
                  last_name: this.form.last_name,
                  nation_id: this.form.nation_id,
                  province_id: this.form.province_id,
                  birthday: this.form.birthday,
                  organization_id: this.form.organization_id,
                  sex: this.sex,
                })
                .then((res) => {
                  if (res.status == 200) {
                    this.$router.push(
                      `/auth/sign-in1?email=${this.form.email}`
                    );
                  }else if(res.status == 1001){
                    this.alreadyEmail = res.message;
                    this.registered = true;
                  }
                });
            } else {
              this.$message.warning("The two passwords are inconsistent");
            }
          } else {
            this.$message.warning("Incomplete registration information");
          }
        } else if (this.local != "") {
          this.form.organization_id = this.local;
          if (
            this.form.first_name !== "" &&
            this.form.last_name !== "" &&
            this.form.password !== "" &&
            this.form.email !== "" &&
            this.form.birthday !== "" &&
            this.form.nation_id !== ""
          ) {
            if (this.form.password == this.confirmPassword) {
              this.$http
                .userRegister({
                  email: this.form.email,
                  password: this.form.password,
                  first_name: this.form.first_name,
                  last_name: this.form.last_name,
                  nation_id: this.form.nation_id,
                  province_id: this.form.province_id,
                  birthday: this.form.birthday,
                  organization_id: this.form.organization_id,
                  sex: this.sex,
                })
                .then((res) => {
                  if (res.status == 200) {
                    this.$router.push(
                      `/auth/sign-in1?email=${this.form.email}`
                    );
                  }else if(res.status == 1001){
                    this.alreadyEmail = res.message;
                    this.registered = true;
                  }
                });
            } else {
              this.$message.warning("The two passwords are inconsistent");
            }
          } else {
            this.$message.warning("Incomplete registration information");
          }
        } else {
          this.$message.warning(
            "National organization selected, please select local organization"
          );
        }
      } else {
        this.form.organization_id = "";
        if (
          this.form.first_name !== "" &&
          this.form.last_name !== "" &&
          this.form.password !== "" &&
          this.form.email !== "" &&
          this.form.birthday !== "" &&
          this.form.nation_id !== ""
        ) {
          if (this.form.password == this.confirmPassword) {
            this.$http
              .userRegister({
                email: this.form.email,
                password: this.form.password,
                first_name: this.form.first_name,
                last_name: this.form.last_name,
                nation_id: this.form.nation_id,
                province_id: this.form.province_id,
                birthday: this.form.birthday,
                organization_id: this.form.organization_id,
                sex: this.sex,
              })
              .then((res) => {
                if (res.status == 200) {
                  this.$router.push(`/auth/sign-in1?email=${this.form.email}`);
                }else if(res.status == 1001){
                  this.alreadyEmail = res.message;
                  this.registered = true;
                }
              });
          } else {
            this.$message.warning("The two passwords are inconsistent");
          }
        } else {
          this.$message.warning("Incomplete registration information");
        }
      }
      // if (this.organ && this.local) {
      //   this.form.organization_id = this.local;
      // } else {
      //   this.form.organization_id = "";
      // }
      // if (
      //   this.form.first_name !== "" &&
      //   this.form.last_name !== "" &&
      //   this.form.password !== "" &&
      //   this.form.email !== "" &&
      //   this.form.birthday !== "" &&
      //   this.form.nation_id !== ""
      // ) {
      //   if (this.form.password == this.confirmPassword) {
      //     this.$http
      //       .userRegister({
      //         email: this.form.email,
      //         password: this.form.password,
      //         first_name: this.form.first_name,
      //         last_name: this.form.last_name,
      //         nation_id: this.form.nation_id,
      //         province_id: this.form.province_id,
      //         birthday: this.form.birthday,
      //         organization_id: this.form.organization_id,
      //         sex: this.sex,
      //       })
      //       .then((res) => {
      //         if (res.status == 200) {
      //           this.$router.push("/auth/sign-in1");
      //         }
      //       });
      //   } else {
      //     this.$message.warning("The two passwords are inconsistent");
      //   }
      // } else {
      //   this.$message.warning("Incomplete registration information");
      // }
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 578px) {
  .SignUp1 {
    /* padding-top: 40px; */
  }
}
::v-deep #noticeToUsers .modal-dialog {
  max-width: 700px !important;
}

::v-deep #privacy .modal-dialog {
  max-width: 700px !important;
}

.SignUp1 {
  .messageError {
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    padding: 10px;
    background: #fffcf3;
    color: #ffba68;
  }
  .title {
    margin-bottom: 83px;
  }

  .contentBox {
    .appendAsterisk::after {
      content: "*";
      color: red;
      display: inline;
      margin-left: 5px;
    }

    .nameInput {
      width: 48%;
    }

    .birthdaySelect {
      width: 31%;
    }
  }

  .tip {
    position: relative;

    .tip-line {
      position: absolute;
      width: 10px;
      height: 10px;
      left: 32%;
      bottom:43px;
      border: 5px solid rgba(0, 0, 0, 0.01);
      border-top: 5px solid #aaa;
    }

    .tip-main {
      position: absolute;
      /* top: 10px; */
      bottom: 53px;
      right: 0px;
      width: 75%;
      background: #fff;
      text-align: center;
      border: 1px solid #aaa;
      border-radius: 5px;
    }
  }
}

.el-date-editor::v-deep {
  .el-input__inner {
    border-radius: 10px;
    border: 1px solid #dcdfe6;
    height: 45px;
  }
}
</style>
